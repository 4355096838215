import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loading from '../../components/Loading';
import Swal from 'sweetalert2';

const ChangeLogo = () => {
  const [logo, setLogo] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogoChange = (e) => {
    setLogo(e.target.files[0]);
  };

  const handleFaviconChange = (e) => {
    setFavicon(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (logo) {
        const logoFormData = new FormData();
        logoFormData.append('logo', logo);
        setLoading(true)

        const logoResponse = await axios.put('https://pmgmgsolutions.online/api/general/logo', logoFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setLoading(false)

        console.log(logoResponse.data.message);
      }

      if (favicon) {
        const faviconFormData = new FormData();
        faviconFormData.append('favicon', favicon);
        setLoading(true)

        const faviconResponse = await axios.put('https://pmgmgsolutions.online/api/general/favicon', faviconFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    setLoading(false)

        console.log(faviconResponse.data.message);
      }
      setLoading(false)


      Swal.fire({
        position: "top-end",
        title: "Display settings updated successfully.",
        timer: 1500,
        customClass: {
            popup: 'custom-swal'
        }
    })
    } catch (error) {
      setLoading(false);
      Swal.fire({
        position: "top-end",
        title: "An error occurred. Please try again.",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
            popup: 'custom-swal-danger'
        }
    })
      if (error.response) {
        console.error('Server responded with an error:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error in setting up the request:', error.message);
      }
      // alert('Error uploading files');
    }
    
  };

  return (
    <div>
    {loading ? <Loading /> : null} {/* Conditionally render the loading component */}

      <div className="container-fluid" >
        <div className="card p-4">
          <form onSubmit={handleSubmit} encType='multipart/form-data'>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <label htmlFor="logo" className="form-label">Logo</label>
                <input 
                  type="file" 
                  name="logo" 
                  className="form-control" 
                  id="logo" 
                  accept="image/*" 
                  onChange={handleLogoChange} 
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <label htmlFor="favicon" className="form-label">Favicon</label>
                <input 
                  type="file" 
                  name="favicon" 
                  className="form-control" 
                  id="favicon" 
                  accept="image/*" 
                  onChange={handleFaviconChange} 
                />
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-warning float-end mt-3">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangeLogo;
