import React, { useEffect, useState, useRef } from 'react';
import { useAppContext } from '../../context/AppContext';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { formatDistanceToNow, parseISO, differenceInSeconds } from 'date-fns';
import data2 from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import {  isToday, isYesterday, format } from 'date-fns';


const Comments = ({ id, taskData, taskUsers, creator ,creatorDetails }) => {
  const { socket } = useAppContext();
  const [comments, setComments] = useState('');
  const [loggedUser, setLoggedUser] = useState([]);
  const [socketComments, setSocketComments] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);
  const [mentionDropdown, setMentionDropdown] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const activeId = localStorage.getItem('id');
  const navigate = useNavigate();
  const commentsEndRef = useRef(null); // Create a ref for scrolling to the bottom
  const inputRef = useRef(null);
  const [dbComments, setDbComments] = useState([]);
  const [myComments, setMyComments] = useState([]);
  const [showMyComments , setShowMyComments] = useState(false);

  

  useEffect(() => {
    if (!activeId) {
      navigate('/login');
    } else {
      axios
        .get(`https://pmgmgsolutions.online/api/admin/adminInfo/`, {
          headers: { Authorization: `${activeId}` },
        })
        .then((res) => {
          setLoggedUser(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status === 404) {
            navigate('/login');
          }
        });
    }
  }, [activeId, navigate]);
   console.log("creatorDetails" , creatorDetails);
   
  const handleChange = (e) => {
    const newText = e.target.value;
    if (newText.length > 7500) {
      
      Swal.fire({
        icon: 'warning',
        title: 'Text Too Long',
        text: 'Your message exceeds the maximum allowed length of 7500 characters.',
        confirmButtonText: 'OK',
      });
      
      return;
    }
    
    adjustHeight();
    setComments(newText);
    if (newText.includes('@')) {
      setMentionDropdown(true);
      const searchTerm = newText.split('@').pop();
      const filtered = taskUsers.filter(user =>
        user
      );
      
      setFilteredUsers(filtered);
    } else {
      setMentionDropdown(false);
      setFilteredUsers([]);
    }
  };
  console.log("filtered" , filteredUsers);
  const adjustHeight = () => {
    const textarea = inputRef.current;
    if (textarea) {
      textarea.style.height = '20px'; // Reset height
      const scrollHeight = textarea.scrollHeight;
    
      // Set the height, but limit it to a maximum of 50px
      textarea.style.height = `${Math.min(scrollHeight, 100)}px`;
  
    }
  };

  useEffect(() => {
    adjustHeight(); // Adjust height on initial render
  }, []);

  // const formatTextWithMentions = (text) => {
  //   let formattedText = text;
  //   taskUsers.forEach(user => {
  //     const mentionPattern = new RegExp(`@${user.name}`, 'g');
  //     formattedText = formattedText.replace(mentionPattern, `<b>@${user.name}</b>`);
  //   });
  //   return formattedText;
  // };
  const formatTextWithMentions = (text) => {
    let formattedText = text;

  // Replace creator's mention with bold text
  if (creatorDetails && formattedText.includes(`@${creatorDetails.name}`)) {
    const creatorRegex = new RegExp(`@${creatorDetails.name}`, 'g');
    formattedText = formattedText.replace(creatorRegex, `<b>@${creatorDetails.name}</b>`);
  }

  // Replace task users' mentions with bold text
  taskUsers.forEach(user => {
    const mentionPattern = new RegExp(`@${user.name}`, 'g');
    formattedText = formattedText.replace(mentionPattern, `<b>@${user.name}</b>`);
  });

  return formattedText;
};

  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      // event.preventDefault(); // Prevents the default newline behavior
      handleSendMessage(event)
    }
  };
  
  const handleSendMessage = (e) => {
    e.preventDefault();
    adjustHeight(); // Adjust height on initial render

    if (comments.trim()) {
      const messageData = {
        fromId: activeId,
        loggedUser: loggedUser,
        toId: id,
        text: comments,
        time: new Date().toISOString(),
  //       usersIds: taskUsers
  // .map((item) => item.id) // Map taskUsers to their IDs
  // .concat(creator) // Add the creator's ID
  // .filter((id) => Number(id) !== Number(activeId)) // Exclude the active user if already present
  // .concat(
  //   !taskUsers.some((user) => Number(user.id) === Number(activeId)) && Number(creator) !== Number(activeId) 
  //     ? Number(activeId)
  //     : null
  // ),
        usersIds: taskUsers.map((item) => item.id).concat(creator , !taskUsers.some((user) => Number(user.id) === Number(activeId)) && Number(creator) !== Number(activeId) 
        ? Number(activeId)
        : null),
      };
      setMyComments((prevMessages) => [...prevMessages, comments]);

      setComments('');
      setShowEmoji(false);
      setMentionDropdown(false);
      setFilteredUsers([]);


      socket.emit('addComments', messageData, (response) => {
        if (response.status === 'ok') {
          const notification = {
            toId: id,
            fromId: activeId,
            usersID:  taskUsers
            .map((item) => item.id)
            .concat(creator)
            .filter((id) => Number(id) !== Number(activeId)),
            // usersID:  taskUsers.map((item) => item.id).concat(creator),
            loggedUser: {
              ...loggedUser,
              taskData: taskData,
            },
            text: comments.length > 30 ? `${loggedUser?.name}: ${comments.substring(0, 30)}...` : `${loggedUser?.name}: ${comments}`,
            time: new Date().toLocaleString(),
            route: `/viewTask/${id}`,
          };
          socket.emit('newNotification', notification, (response) => {
            if (response && response.status === 'ok') {
              console.log(response.msg);
            } else {
              console.error('Message delivery failed or no response from server');
            }
          });
          setComments('');
          setShowMyComments(false);
      adjustHeight();
      if (inputRef.current) {
        inputRef.current.style.height = '40px'; // Reset height explicitly
        inputRef.current.focus(); // Focus on the textarea if needed
      }

        } else {
          setShowMyComments(true);
          console.error('Message delivery failed');
        }
      });


    }
  };

  useEffect(() => {
    const handleMessage = (data) => {
      if(Number(data.toId) === Number(id)){
        setSocketComments((prevMessages) => [...prevMessages, data]);
      }
    };



    socket.on('getUserComment', handleMessage);
    setShowMyComments(false);

    return () => {
      socket.off('getUserComment', handleMessage);
    };
  }, [socket]);

  console.log("mySocketComments" ,myComments);
  
  useEffect(() => {
    // Scroll to bottom when new comments are added
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  useEffect(() => {
    // Scroll to bottom when new comments are added
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [myComments]);
  useEffect(() => {
    // Scroll to bottom when new comments are added
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [socketComments]);

  console.log("socketComments" ,socketComments);
  useEffect(() => {
    // Scroll to bottom when new comments are added
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [dbComments]);
  const formatRelativeTime = (isoDate) => {
    if (!isoDate) return 'Invalid date';
    try {
      const date = parseISO(isoDate);
      const secondsAgo = differenceInSeconds(new Date(), date);
      if (secondsAgo < 60) {
        return 'just now';
      }
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const handleShowEmoji = () => {
    setShowEmoji(!showEmoji);
  };

  const handleEmojiSelect = (emoji) => {
    setComments(comments + emoji.native);
  };

  const handleUserSelect = (user) => {
    const mentionText = `@${user.name} `;
    const newComments = comments.substring(0, comments.lastIndexOf('@')) + mentionText;
    setComments(newComments);
    setMentionDropdown(false);
    inputRef.current.focus();
  };

 useEffect(() => {
  axios.get(`https://pmgmgsolutions.online/api/task/getComments`, {
    params: {
      taskId: id,
      // activeId: Number(activeId) // This might be restricting based on activeId
    }
  })
  .then((res) => {
    setDbComments(res.data);
    console.log("getComments", res.data);
  })
  .catch((err) => {
    console.log(err);
  });
}, []);


  let lastDate = null;
  const formatDate = (date) => {
    const parsedDate = parseISO(date);
    if (isToday(parsedDate)) {
      return 'Today';
    } else if (isYesterday(parsedDate)) {
      return 'Yesterday';
    } else {
      return format(parsedDate, 'MMM d, yyyy'); // e.g., Sep 5, 2024
    }
  };
  
  return (
    <div className='app-scroll'>
      <div
        className=" app-scroll"
        id="navs-top-tasks-1"
        role="tabpanel"
        style={{ height: '450px' }}
      >
        
       
        <div className="comments-wrapper app-scroll">
     
          {dbComments && dbComments.map((item, index) => {
            const commentDate = formatDate(item.time); // Format the date of the comment
            const showDateDivider = lastDate !== commentDate; // Check if the date has changed
            lastDate = commentDate; // Update the lastDate to current commentDate
          
          
            return(
              <>
                            {showDateDivider && 
                            <div>
  
                              <p className='messenger-title3' >
                                <span className='messenger-title-span'>{commentDate}</span>
                              </p>
                            </div>
                            }

<div className={item.loggedUser.id === Number(activeId) ? `comment-container mb-4` : `comment-container1 mb-4`} key={index}>
  <img
    src={item?.loggedUser?.pfpImage}
    style={{ objectFit: 'cover' }}
    className="comment-user-image avatar"
    alt="User Image"
  />
  <div className="comment-content"  style={{ wordBreak: "break-word", }}>
    <div className="comment-header">
      <span className="comment-username text-capitalize">
        {item.loggedUser.name}
        {item.loggedUser.id === Number(activeId) ? ' (You)' : ''}
      </span>
      <span className="comment-time">{formatRelativeTime(item.time)}</span>
    </div>
    <p 
  style={{ 
    wordBreak: "break-word", 
  }} 
  
>
  {item.text?.split('\n').map((line, index) => (
    <span key={index } className="comment-text" >
      <span dangerouslySetInnerHTML={{ __html: formatTextWithMentions(line) }} />
      <br />
    </span>
  ))}
</p>
    {/* {item.text.split('\n').map((line, index) => (
    <span className="comment-text" key={index}>
      <p className="comment-text" dangerouslySetInnerHTML={{ __html: formatTextWithMentions(line) }} />
      
   
    </span>
  ))} */}
  </div>
</div>

</>
              )})}

          {socketComments && socketComments.map((item, index) => (
            <div className="comment-container mb-4" key={index}>
              <img
                src={item?.loggedUser?.pfpImage}
                style={{ objectFit: 'cover' }}
                className="comment-user-image avatar"
                alt="User Image"
              />
              <div className="comment-content">
                <div className="comment-header">
                  <span className="comment-username text-capitalize">
                    {item.loggedUser.name}
                    {item.loggedUser.id === Number(activeId) ? ' (You)' : ''}
                  </span>
                  <span className="comment-time">{formatRelativeTime(item.time)}</span>
                </div>
                <p 
  style={{ 
    wordBreak: "break-word", 
  }} 
  
>
  {item.text?.split('\n').map((line, index) => (
    <span key={index } className="comment-text" >
      <span dangerouslySetInnerHTML={{ __html: formatTextWithMentions(line) }} />
      <br />
    </span>
  ))}
</p>
 {/* {item.text.split('\n').map((line, index) => (
    <span className="comment-text" key={index}>
      <p className="comment-text" dangerouslySetInnerHTML={{ __html: formatTextWithMentions(line) }} />
    </span>
  ))} */}
                {/* <p className="comment-text" dangerouslySetInnerHTML={{ __html: formatTextWithMentions(item.text) }} /> */}
              </div>
            </div>
          ))}

        {showMyComments && myComments && myComments.map((item, index) => (
            <div className="comment-container mb-4" key={index}>
              <div class="error__icon mt-2">
              <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z" fill="#393a37"></path></svg>
          </div>
              <img
                src={loggedUser?.pfpImage}
                style={{ objectFit: 'cover' }}
                className="comment-user-image avatar"
                alt="User Image"
              />
              <div className="comment-content">
                <div className="comment-header">
                  <span className="comment-username text-capitalize">
                    {loggedUser.name}
                    {' (You)'}
                  </span>
                  <span className="comment-time">just now                  </span>
                </div>
                <p 
  style={{ 
    wordBreak: "break-word", 
  }} 
  
>
  {item.text?.split('\n').map((line, index) => (
    <span key={index } className="comment-text" >
      <span dangerouslySetInnerHTML={{ __html: formatTextWithMentions(line) }} />
      <br />
    </span>
  ))}
</p>   {/* {item.text.split('\n').map((line, index) => (
    <span className="comment-text" key={index}>
      <p className="comment-text" dangerouslySetInnerHTML={{ __html: formatTextWithMentions(line) }} />
    </span>
  ))} */}
                {/* <p className="comment-text" dangerouslySetInnerHTML={{ __html: formatTextWithMentions(item) }} /> */}
              </div>
            </div>
          ))}


          
          {/* This div will be used to scroll to the bottom */}
          <div ref={commentsEndRef} />
        </div>
        {showEmoji && (
           <div className='emogi-picker2 app-scroll' style={{ width: '350px', height: '32%' , }} >
           <Picker
             data={data2}
             onEmojiSelect={handleEmojiSelect}
             style={{ width: '40px', height: '40px' }} // Full width and height of the parent container
           />
         </div>
        )}
        {mentionDropdown && (
          <div className="mention-dropdown">
             <div
                      className={` form-select-sm select-bg-label-secondary  text-capitalize pt-2   cursor-pointer`}
                      data-original-color-class="select-bg-label-secondary"
                    >

                
                      {filteredUsers && filteredUsers.length > 0 && filteredUsers.map((user, dbIndex) => (
                     <>
                     <div  onClick={() => handleUserSelect(creatorDetails)}>
                        <img src={creatorDetails.pfpImage} style={{width:'40px', height:'40px' , borderRadius:'50%' , marginBottom:'10px'}} alt="" />
                        <span style={{marginLeft:'10px' , fontSize:'15px' , fontWeight:'bold'}}>{creatorDetails.name}</span>
                    </div>

                      <div  onClick={() => handleUserSelect(user)}>
                        <img src={user.pfpImage} style={{width:'40px', height:'40px' , borderRadius:'50%' , marginBottom:'10px'}} alt="" />
                        <span style={{marginLeft:'10px' , fontSize:'15px' , fontWeight:'bold'}}>{user.name}</span>
                      </div>
                     </>
                    ))}
                   
                  </div>
           
          </div>
        )}
        <form onSubmit={handleSendMessage}>
          <div className="messageBox">
            <div className="fileUploadWrapper cursor-pointer" onClick={handleShowEmoji}>
              <i className='bx bx-smile'></i>
              <span className="tooltip">Emoji</span>
            </div>
            <textarea
              required
              onChange={handleChange}
              value={comments}
              placeholder="Message..."
              type="text"
              onKeyDown={handleKeyDown}
              style={{resize:'none'}}
              className='app-scroll'
              ref={inputRef}
              id="messageInput"
            />
            <button id="sendButton">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 664 663">
                <path
                  fill="none"
                  d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                ></path>
                <path
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="33.67"
                  stroke="#6c6c6c"
                  d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                ></path>
              </svg>
            </button>
          </div>
        </form>
      </div>

    </div>
  );
};

export default Comments;

// import React, { useEffect, useState, useRef } from 'react';
// import { useAppContext } from '../../context/AppContext';
// import Swal from 'sweetalert2';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { formatDistanceToNow, parseISO, differenceInSeconds } from 'date-fns';
// import data2 from '@emoji-mart/data';
// import Picker from '@emoji-mart/react';
// const Comments = ({ id, taskData, taskUsers, creator }) => {
//   const { socket } = useAppContext();
//   const [comments, setComments] = useState('');
//   const [loggedUser, setLoggedUser] = useState([]);
//   const [socketComments, setSocketComments] = useState([]);
//   const activeId = localStorage.getItem('id');
//   const navigate = useNavigate();
//   const commentsEndRef = useRef(null); // Create a ref for scrolling to the bottom
//   const [showEmogi , setShowEmogi] = useState(false);
  
//   useEffect(() => {
//     if (!activeId) {
//       navigate('/login');
//     } else {
//       axios
//         .get(`https://pmgmgsolutions.online/api/admin/adminInfo/`, {
//           headers: { Authorization: `${activeId}` },
//         })
//         .then((res) => {
//           setLoggedUser(res.data);
//         })
//         .catch((err) => {
//           console.error(err);
//           if (err.response && err.response.status === 404) {
//             navigate('/login');
//           }
//         });
//     }
//   }, [activeId, navigate]);

//   const handleChange = (e) => {
//     const newText = e.target.value;
//     if (newText.length > 7500) {
//       Swal.fire({
//         icon: 'warning',
//         title: 'Text Too Long',
//         text: 'Your message exceeds the maximum allowed length of 7500 characters.',
//         confirmButtonText: 'OK',
//       });
//       return;
//     }
//     setComments(newText);
//   };

//   const handleSendMessage = (e) => {
//     e.preventDefault();
//     if (comments.trim()) {
//       const messageData = {
//         fromId: activeId,
//         loggedUser: loggedUser,
//         toId: id,
//         text: comments,
//         time: new Date().toISOString(),
//         usersIds: taskUsers.map((item) => item.id).concat(creator),
//       };

//       setComments('');
//       setShowEmogi(false);
//       socket.emit('addComments', messageData, (response) => {
//         if (response.status === 'ok') {
//           const notification = {
//             fromId: activeId,
//             usersID: taskUsers.map((item) => item.id),
//             loggedUser: {
//               ...loggedUser,
//               taskData: taskData,
//             },
//             text: comments.length > 30 ? `${loggedUser?.name}: ${comments.substring(0, 30)}...` : `${loggedUser?.name}: ${comments}`,
//             time: new Date().toLocaleString(),
//             route: `/groupchat/${id}`,
//           };

//           setComments('');
//         } else {
//           console.error('Message delivery failed');
//         }
//       });
//     }
//   };

//   useEffect(() => {
//     const handleMessage = (data) => {
//       setSocketComments((prevMessages) => [...prevMessages, data]);
//     };

//     socket.on('getUserComment', handleMessage);

//     return () => {
//       socket.off('getUserComment', handleMessage);
//     };
//   }, [socket]);

//   useEffect(() => {
//     // Scroll to bottom when new comments are added
//     if (commentsEndRef.current) {
//       commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [socketComments]);

//   const formatRelativeTime = (isoDate) => {
//     if (!isoDate) return 'Invalid date';
//     try {
//       const date = parseISO(isoDate);
//       const secondsAgo = differenceInSeconds(new Date(), date);
//       if (secondsAgo < 60) {
//         return 'just now';
//       }
//       return formatDistanceToNow(date, { addSuffix: true });
//     } catch (error) {
//       console.error('Error formatting date:', error);
//       return 'Invalid date';
//     }
//   };


  
  
//   const handleShowEmogi = () => {
//     setShowEmogi(!showEmogi);
//   }

//   const handleEmojiSelect = (emoji) => {
//     console.log(emoji.native);
//     setComments(comments + emoji.native);


//   };

//   return (
//     <div className='app-scroll'>
//       <div
//         className="tab-pane fade show active app-scroll"
//         id="navs-top-tasks-1"
//         role="tabpanel"
//         style={{ height: '450px' }}
//       >
//         <div className="comments-wrapper app-scroll">
//           {socketComments && socketComments.map((item, index) => (
//             <div className="comment-container mb-4" key={index}>
//               <img
//                 src={item?.loggedUser?.pfpImage}
//                 style={{ objectFit: 'cover' }}
//                 className="comment-user-image avatar"
//                 alt="User Image"
//               />
//               <div className="comment-content">
//                 <div className="comment-header">
//                   <span className="comment-username text-capitalize">
//                     {item.loggedUser.name}
//                     {item.loggedUser.id === Number(activeId) ? ' (You)' : ''}
//                   </span>
//                   <span className="comment-time">{formatRelativeTime(item.time)}</span>
//                 </div>
//                 <p className="comment-text">{item.text}</p>
//               </div>
//             </div>
//           ))}
//           {/* This div will be used to scroll to the bottom */}
//           <div ref={commentsEndRef} />
//         </div>
//         {showEmogi === true &&
//               <div className='emogi-picker2' style={{position:'absolute' , marginTop:'-30px'}}>
// <Picker data={data2} onEmojiSelect={handleEmojiSelect} style={{width:'100%' , height:'50vh'}} />


//                 </div>
//             }
//         <form action="" onSubmit={handleSendMessage}>
//           <div className="messageBox">
//             <div className="fileUploadWrapper" onClick={handleShowEmogi}>
//               <i className='bx bx-smile'></i>
//               <span className="tooltip">Emoji</span>
//             </div>
//             <input
//               required
//               onChange={handleChange}
//               value={comments}
//               placeholder="Message..."
//               type="text"
//               id="messageInput"
//             />
//             <button id="sendButton">
//               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 664 663">
//                 <path
//                   fill="none"
//                   d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
//                 ></path>
//                 <path
//                   strokeLinejoin="round"
//                   strokeLinecap="round"
//                   strokeWidth="33.67"
//                   stroke="#6c6c6c"
//                   d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
//                 ></path>
//               </svg>
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Comments;


// import React, { useEffect, useState } from 'react'
// import { useAppContext } from '../../context/AppContext'
// import Swal from 'sweetalert2'
// import { useNavigate } from 'react-router-dom'
// import axios from 'axios'
// import { formatDistanceToNow, parseISO, differenceInSeconds } from 'date-fns';


// const Comments = ({id , taskData , taskUsers , creator}) => {

//     console.log("taskData22" ,creator ,  id, taskData ,taskUsers.map((item) => item.id));
    
//     const {socket} = useAppContext()
//     const [comments, setComments] = useState('')
//     const [loggedUser , setLoggedUser] = useState([])
//     const [socketComments , setSocketComments] = useState([])
//     const activeId = localStorage.getItem("id");
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (!activeId) {
//           navigate("/login");
//         } else {
//           axios
//             .get(`https://pmgmgsolutions.online/api/admin/adminInfo/`, {
//               headers: { Authorization: `${activeId}` },
//             })
//             .then((res) => {
//               setLoggedUser(res.data);
//             })
//             .catch((err) => {
//               console.error(err);
//               if (err.response && err.response.status === 404) {
//                 navigate("/login");
//               }
//             });
//         }
//       }, [activeId, navigate]);
//     const handleChange = (e) => {

//     const newText = e.target.value;
    
//     if (newText.length > 7500) {
//         // Show SweetAlert if text length exceeds 7500 characters
//         Swal.fire({
//         icon: 'warning',
//         title: 'Text Too Long',
//         text: 'Your message exceeds the maximum allowed length of 7500 characters.',
//         confirmButtonText: 'OK',
//         });
//         return
//     }
//     setComments(newText);       
//     }

//     const handleSendMessage  = (e) => {
//         e.preventDefault();
        
//         if (comments.trim() ) {
          
//           const messageData = {
//             fromId: activeId,
//             loggedUser:loggedUser,
//             toId: id,
//             text:comments,
//             time: new Date().toISOString(),
//             usersIds: taskUsers.map(item => item.id).concat(creator),
            
//             }
            
//           setComments('');
//           socket.emit('addComments', messageData, (response) => {
//             if (response.status === 'ok') {
//               // console.log(response.msg);
//               const notification = {
//                 fromId: activeId,
//                 usersID: taskUsers.map((item) => item.id),
//                 loggedUser: {
//                   ...loggedUser,
//                   taskData: taskData,
//                   // You can add other properties from groupData if needed
//                   },          
//                 text:comments.length > 30 ? `${loggedUser?.name}: ${comments.substring(0, 30)}...` : `${loggedUser?.name}: ${comments}`,
//                 time: new Date().toLocaleString(),
//                 route: `/groupchat/${id}`,
//               };
    
//               setComments('');
    
//             //   socket.emit('newNotification', notification, (response) => {
//             //     if (response && response.status === 'ok') {
//             //       console.log(response.msg);
//             //     } else {
//             //       console.error('Message delivery failed or no response from server');
//             //     }
//             //   });
//             } else {
//               console.error('Message delivery failed');
//             }
//           });
    
       
//         }
//       };



//       useEffect(() => {
//         const handleMessage = (data) => {
//           console.log("getUserComment: ", data);
//           setSocketComments(prevMessages => [...prevMessages, data]);
//         };
        
//         socket.on('getUserComment', handleMessage);
        
//         return () => {
//           socket.off('getUserComment', handleMessage);
//         };
//       }, [socket]);
      
//       const formatRelativeTime = (isoDate) => {
//         if (!isoDate) return 'Invalid date';
      
//         try {
//           // Parse the ISO date string into a Date object
//           const date = parseISO(isoDate);
          
//           // Calculate the difference in seconds
//           const secondsAgo = differenceInSeconds(new Date(), date);
          
//           // Return 'just now' if less than a minute ago
//           if (secondsAgo < 60) {
//             return 'just now';
//           }
      
//           // Calculate the relative time from now
//           return formatDistanceToNow(date, { addSuffix: true });
//         } catch (error) {
//           console.error('Error formatting date:', error);
//           return 'Invalid date';
//         }
//       };
      
      
//   return (
//     <div className='app-scroll '>
        
//          <div
//       className="tab-pane fade show active app-scroll"
//       id="navs-top-tasks-1"
//       role="tabpanel"
//       style={{ height: "450px" }}
//     >
//      <div className="comments-wrapper app-scroll">
//        {socketComments && socketComments.map((item , index) => (
//             <div className="comment-container mb-4" key={index}>
//             <img
//               src={item?.loggedUser?.pfpImage}
//               style={{objectFit:"cover"}}
//               className="comment-user-image avatar"
//               alt="User Image"
//             />
//             <div className="comment-content">
//               <div className="comment-header">
//                 <span className="comment-username text-capitalize">{item.loggedUser.name}{item.loggedUser.id === Number(activeId) ? " (You)" : ""}</span>
//                 <span className="comment-time">{formatRelativeTime(item.time)}</span>

//               </div>
//               <p className="comment-text">
//                 {item.text}
//               </p>
//             </div>
//           </div>
  
//        ))}
       
//       </div>

//         <form action="" onSubmit={handleSendMessage}>
//       <div className="messageBox">
//         <div className="fileUploadWrapper">
//           <i class='bx bx-smile' ></i>
//             <span className="tooltip">Emogi</span>
//         </div>
//         <input
//           required
//           onChange={handleChange}
//           value={comments}
//           placeholder="Message..."
//           type="text"
//           id="messageInput"
//         />
//         <button id="sendButton" >
//           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 664 663">
//             <path
//               fill="none"
//               d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
//             ></path>
//             <path
//               strokeLinejoin="round"
//               strokeLinecap="round"
//               strokeWidth="33.67"
//               stroke="#6c6c6c"
//               d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
//             ></path>
//           </svg>
//         </button>
//       </div>
//         </form>
//     </div>
//     </div>
//   )
// }

// export default Comments
