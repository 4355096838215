import React from 'react'
import '../App.css'
const Loading = () => {
  return (
    <div>
      <section class="circle-group">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
    </section>

    </div>
  )
}

export default Loading
