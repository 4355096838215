import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
const socketConnect = io('https://pmgmgsolutions.online', {
  path: '/socket.io/',  // Make sure the path matches your Nginx config
  transports: ['websocket']  // Ensure WebSocket transport is being used
});

const AppContext = createContext();
export const AppProvider = ({ children }) => {
  const {id} = useParams();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [socket , setSocket] = useState(socketConnect);
  const [notifyLength , setnotifyLength] = useState(0);
  const [appNotification , setAppNotification] = useState(1)
  const [notify , setNotify] = useState(0)
  
  // console.log("App Socket : ", socket);
  // New state for API data
  const [AppContextStatus, setAppContextStatus] = useState([]);
  const [AppContextPriority, setAppContextPriority] = useState([]);

  const fetchStatuses = async () => {
    try {
      const statusRes = await axios.get(
        "https://pmgmgsolutions.online/api/projectStatus/getAllStatus"
      );
      setAppContextStatus(statusRes.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPriority = async () => {
    try {
      const priorityRes = await axios.get(
        "https://pmgmgsolutions.online/api/projectPriority/getAllPriorities"
      );
      setAppContextPriority(priorityRes.data);
    } catch (err) {
      console.log(err);
    }
  };
  const activeId = localStorage.getItem("id");

  // Fetch statuses on component mount
  useEffect(() => {
    fetchStatuses();
    fetchPriority();
  }, []);
  const [activeUsers, setActiveUsers] = useState([]);
  const [activeStatus, setActiveStatus] = useState(false);


  socket.on('allusers', (res) => {
    // console.log('allusers:', res);
  
    // Ensure `res` is an array
    if (Array.isArray(res)) {
        // Extract and set active user IDs
        setActiveUsers(res);
        
        // Convert `id` to a number for comparison
        const numericId = Number(id);
  
        // Filter users based on the matching `paramsId`
        const filter = res.filter((user) => Number(user.id) === numericId);
        // console.log('filter:', filter);
        
        // Update active status based on filter results
        if (filter.length > 0) {
            setActiveStatus(true);
        } else {
            setActiveStatus(false);
        }
    } else {
        console.error('Expected an array but received:', res);
    }
  });
  const location = useLocation(); // Hook to get current location
  // console.log(location);
  
  return (
    <AppContext.Provider
      value={{
        id,
        isMenuExpanded,
        setIsMenuExpanded,
        setIsOpen,
        isOpen,
        setIsOpen1,
        isOpen1,
        isOpen2,
        setIsOpen2,
        AppContextStatus,
        AppContextPriority,
        socket,
        location,
        notifyLength,
        setnotifyLength,
        setAppNotification,
        appNotification,
        notify , setNotify,activeUsers
        // options
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useAppContext = () => useContext(AppContext);
